<template>
  <v-container fluid>
    <h3>Session Information</h3>
    <template v-if="item && connector">
      <v-card class="mt-2">
        <v-card-text>
          <div style="
              font-size: 14px;
              font-weight: 500;
              line-height: 17px;
              letter-spacing: 0px;
              text-align: left;
              color: rgba(102, 102, 102, 1);
            ">
            Connector
          </div>
          <v-list-item style="padding-left: 0px !important">
            <v-list-item-avatar :rounded="false" :size="80" tile>
              <v-img :src="require('@/assets/images/connector.png')"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title color="primary" style="
                  font-size: 24px;
                  font-weight: 600;
                  line-height: 29px;
                  letter-spacing: 0em;
                  text-align: left;
                  color: rgba(74, 70, 67, 1);
                ">
                {{ item.uniqueId }}
              </v-list-item-title>
              <v-list-item-subtitle style="
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 19px;
                  letter-spacing: 0px;
                  text-align: left;
                  color: rgba(102, 102, 102, 1);
                ">{{
      item.chargerVendor && item.chargerVendor.name
    }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-row class="mt-2">
            <v-col class="label"> Fee per kWh </v-col>
            <v-col class="text-right label-value">
              {{ chargingSessionPointPerKwh | currency }} VND
            </v-col>
          </v-row>
          <v-divider class="my-4"></v-divider>
          <v-row>
            <v-col class="label-strong"> Session </v-col>
            <v-col class="text-right label-value">
              <v-chip color="red" small text-color="white"> Unpaid </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label"> Time </v-col>
            <v-col class="text-right label-value-normal">
              {{ moment().format("YYYY-MM-DD") }}
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col class="label"> Total point(s) </v-col>
            <v-col class="text-right label-value-strong">
              {{ chargingSessionMinPoint }}
            </v-col>
          </v-row> -->
        </v-card-text>
      </v-card>
      <v-card class="mt-4">
        <v-card-text v-if="!userManager.isGuest()">
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-icon large color="primary">mdi-star-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle>Balance</v-list-item-subtitle>
              <v-list-item-title class="font-weight-bold">
                {{ point | currency }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-btn color="primary" text style="text-transform: initial" to="/app/settings/payment_transactions_m">
                Manage
              </v-btn>
            </v-list-item-icon>
          </v-list-item>

          <div class="py-4">
            <x-btn :x-options="startChargingBtnOptions" :x-context="self"
              v-if="point >= chargingSessionMinPoint || item.tenant.isPostpaid"></x-btn>
            <v-btn block color="primary" to="/app/settings/payment_transactions_m" v-else>Add points</v-btn>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <v-btn block color="primary" :to="`/login?redirect=${$route.path}`">Login</v-btn>
        </v-card-text>
      </v-card>
    </template>
    <loading v-else-if="initializeLoading"></loading>
    <div class="red--text text-center" v-else-if="initializeError">
      {{ initializeError }}
    </div>
    <div class="text-center" v-else>
      Charging Station or Connector not found
    </div>
  </v-container>
</template>

<script>
import coreApiClient from "@/services/apis/coreApiClient";
import Vue from "vue";
import userManager from "@/services/userManager";
import moment from "moment";
import Loading from "@/components/Loading.vue";

import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import PaymentMethodInputVue from "./settings/partials/payment-transactions/PaymentMethodInput.vue";
import PointInputVue from "./settings/partials/payment-transactions/PointInput.vue";


export default Vue.extend({
  components: { Loading },
  data() {
    return {
      tenantSetting: null,
      addPointBtnOptions: null,
      paymentAmount: 0,
      userManager,
      self: this,
      moment,
      startChargingBtnOptions: {
        attrs: {
          color: "primary",
          block: true,
        },
        content: {
          text: "Start Charging",
        },
        target: {
          tooltip: {
            content: {
              text: "Start Charging",
            },
          },
          confirm: {
            content: {
              content: {
                html: "Are you sure you want to start charging?",
              },
            },
            on: {
              async xYes({ self }) {
                const yesBtn = self.yesBtnContainer.value;
                const btn = self.context();
                const { $router, item, connector } = btn.context();
                yesBtn.loading.value = true;
                const result = await coreApiClient.call(
                  "chargingStations",
                  "startCharging",
                  {
                    id: item.id,
                    connectorId: connector.connectorId,
                  }
                );
                yesBtn.loading.value = false;
                if (result) {
                  self.hide();
                  $router.push(
                    `/app/charging_details_m/${item._id}/${connector.connectorId}?chargingSessionId=${result._id}`
                  );
                }
              },
            },
          },
        },
      },
      point: 0,
      initializeLoading: false,
      initializeError: null,
      item: null,
      connector: null,
      chargingSessionMinPoint: 1,
      chargingSessionPointPerKwh: 0,
    };
  },
  methods: {
    makeAddPointBtnOptions(point, sessionIdPayment) {
      const amountPoint = Math.round(point);
      return {
        attrs: {
          color: "primary",
          dark: false,
          small: true,
          class: "ml-4",
          height: "30px",
        },
        content: {
          text: "Payment Last Session",
        },
        target: {
          dialog: {
            handlers: {
              initialize() {
                this.options.content.buttons.save.states.enabled = new DataContainer(false);
              },
            },
            attrs: {
              width: "700px",
            },
            content: {
              toolbar: {
                title: "Payment Last Session",
              },
              content: {
                type: "XForm",
                makeAttrs(attrs, dialog) {
                  attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                  attrs.xContext = dialog;
                  attrs.xData = new DataContainer({});
                  return attrs;
                },
                attrs: {
                  xOptions: {
                    content: {
                      sections: {
                        default: {
                          fields: {
                            point: {
                              type: PointInputVue,
                              attrs: {
                                label: "Amount",
                                hideLabel: true,
                                required: true,
                                items: [amountPoint],
                                tenantSetting: this.tenantSetting,
                              },
                              ext: {
                                defaultValue: amountPoint,
                              },
                            },
                            paymentMethod: {
                              type: PaymentMethodInputVue,
                              attrs: {
                                label: "Payment Method",
                                hideLabel: true,
                                required: true,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              buttons: {
                cancel: {
                  ext: {
                    hidden: true,
                  },
                },
                save: {
                  attrs: {
                    block: true,
                    text: false,
                  },
                  content: {
                    text: "Pay",
                  },
                  states: {},
                  on: {
                    async xClick({ self }) {
                      const dialog = self.context();
                      // const { tenantSetting } = dialog.context().context();
                      const form = dialog.contentContainer.value;
                      const data = form.getData();
                      let apiName = "";
                      switch (data.paymentMethod.type) {
                        case "vnpay":
                          apiName = "integrationVnpay";
                          break;
                        case "momo":
                          apiName = "integrationMomo";
                          break;
                        default:
                          apiName = "integrationVnpay";
                          break;
                      }

                      const amount = data.point;

                      self.loading.value = true;
                      const result = await coreApiClient.call(apiName, "createPaymentUrl", {
                        amount,
                        id: data.paymentMethod.id,
                        device: "web",
                        sessionId: sessionIdPayment
                      });
                      self.loading.value = false;
                      if (result) {
                        location.href = result;
                      }
                    },
                  },
                },
              },
            },
          },
        },
      };
    },
    async initializeGetChargingStations() {
      const id = this.$route.params.id;
      const connectorId = this.$route.params.connectorId;

      this.initializeLoading = true;
      await this.getLastChargingUnPaid();
      try {
        const item = await coreApiClient.call("chargingStations", "findById", {
          id,
        });
        if (item) {
          this.item = item;
          this.chargingSessionMinPoint =
            item.tenant?.setting?.chargingSessionMinPoint || 1;
          this.chargingSessionPointPerKwh =
            item.tenant?.setting?.chargingSessionPointPerKwh || 0;
        }
        if (this.item) {
          const connector =
            this.item.details &&
            this.item.details.connectors &&
            this.item.details.connectors.find(
              (c) => +c.connectorId === +connectorId
            );
          if (connector) {
            this.connector = connector;
          }
        }

        if (!userManager.isGuest()) {
          const { point } = await coreApiClient.call("users", "findById", {
            id: userManager.getUserInfo()._id,
          });
          this.point = point || 0;
        }
      } catch (e) {
        this.initializeError = e;
      } finally {
        this.initializeLoading = false;
      }
    },
    async getLastChargingUnPaid() {
      {
        this.tenantSetting = await coreApiClient.call(
          "tenants",
          "getSetting",
          {
            id: userManager.getUserInfo().tenant._id,
          }
        );
      }
      const chargingSessions = await coreApiClient.call("chargingSessions", "findOne", {
        payload: JSON.stringify({
          limit: -1,
          filters: [
            {
              key: "isPaid",
              operator: "equal_to",
              value: false,
            },
          ],
        }),
      });
      if (chargingSessions && chargingSessions.point) {
        this.paymentAmount = chargingSessions.point;
        this.addPointBtnOptions = this.makeAddPointBtnOptions(chargingSessions.point, chargingSessions.id);
      }
    },
  },
  async created() {
    this.initializeGetChargingStations();
  },
});
</script>

<style scoped>
.label {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(102, 102, 102, 1);
}

.label-value {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: right;
  color: rgba(102, 102, 102, 1);
}

.label-strong {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(74, 70, 67, 1);
}

.label-value-normal {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: right;
  color: rgba(102, 102, 102, 1);
}

.label-value-strong {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: right;
  color: rgba(51, 51, 51, 1);
}
</style>